import { EntityList } from "./Universal";

export function unique_ship_name(entities: EntityList): string { 
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const name = SHIP_NAMES[Math.floor(Math.random() * SHIP_NAMES.length)];
        if (!entities.ships.find((ship) => ship.name === name)) {
            return name;
        }
    }
}
const SHIP_NAMES = [
  "Aegis",
  "Apollo",
  "Argo",
  "Artemis",
  "Astraeus",
  "Aurora",
  "Avalon",
  "Bellerophon",
  "Boreas",
  "Calypso",
  "Cassiopeia",
  "Centurion",
  "Cerberus",
  "Challenger",
  "Chimera",
  "Comet",
  "Constellation",
  "Corsair",
  "Daedalus",
  "Destiny",
  "Discovery",
  "Draco",
  "Eclipse",
  "Elysium",
  "Endeavour",
  "Eon",
  "Equinox",
  "Erebus",
  "Eternity",
  "Europa",
  "Falcon",
  "Galaxy",
  "Gemini",
  "Genesis",
  "Gladiator",
  "Goliath",
  "Griffin",
  "Halcyon",
  "Harbinger",
  "Helios",
  "Hercules",
  "Horizon",
  "Hydra",
  "Icarus",
  "Infinity",
  "Intrepid",
  "Invader",
  "Janus",
  "Jason",
  "Javelin",
  "Jupiter",
  "Kraken",
  "Kronos",
  "Leviathan",
  "Liberator",
  "Lucifer",
  "Magellan",
  "Manticore",
  "Mariner",
  "Mars",
  "Mercury",
  "Meridian",
  "Meteor",
  "Minerva",
  "Minotaur",
  "Monarch",
  "Nemesis",
  "Neptune",
  "Nova",
  "Odyssey",
  "Orion",
  "Osiris",
  "Pegasus",
  "Perseus",
  "Phoenix",
  "Pioneer",
  "Polaris",
  "Poseidon",
  "Prometheus",
  "Ranger",
  "Raptor",
  "Redeemer",
  "Relentless",
  "Renegade",
  "Requiem",
  "Revelation",
  "Rhino",
  "Sagittarius",
  "Salamander",
  "Samurai",
  "Sanctuary",
  "Saturn",
  "Scimitar",
  "Scorpion",
  "Sentinel",
  "Seraph",
  "Siren",
  "Solstice",
  "Sovereign",
  "Spartan",
  "Spectre",
  "Sphinx",
  "Spirit",
  "Starfire",
  "Stardust",
  "Stormcrow",
  "Strider",
  "Titan",
  "Triumph",
  "Typhoon",
  "Ulysses",
  "Unicorn",
  "Valkyrie",
  "Vanguard",
  "Venus",
  "Victory",
  "Voyager",
  "Warlock",
  "Wraith",
  "Zeus",
  "Zenith",
  "Andromeda",
  "Antares",
  "Aquila",
  "Arcturus",
  "Aries",
  "Atlas",
  "Bellatrix",
  "Capella",
  "Carina",
  "Centauri",
  "Cetus",
  "Corona",
  "Cygnus",
  "Deneb",
  "Dorado",
  "Eridanus",
  "Fornax",
  "Gemini",
  "Hercules",
  "Hydra",
  "Leo",
  "Libra",
  "Lyra",
  "Orion",
  "Pegasus",
  "Perseus",
  "Pisces",
  "Sagittarius",
  "Scorpio",
  "Taurus",
  "Ursa",
  "Virgo",
  "Aquarius",
  "Capricorn",
  "Phoenix",
  "Nebula",
  "Quasar",
  "Pulsar",
  "Black Hole",
  "Supernova",
  "Asteroid",
  "Comet",
  "Meteoroid",
  "Aurora",
  "Borealis",
  "Equinox",
  "Solstice",
  "Zenith",
  "Nadir",
  "Cosmos",
  "Universe",
  "Galaxy",
  "Star",
  "Planet",
  "Moon",
  "Sun",
  "Earth",
  "Mars",
  "Jupiter",
  "Venus",
  "Mercury",
  "Saturn",
  "Neptune",
  "Uranus",
  "Pluto",
  "Ceres",
  "Eris",
  "Makemake",
  "Haumea",
  "Voyager",
  "Pioneer",
  "Galileo",
  "Cassini",
  "Juno",
  "New Horizons",
  "Rosetta",
  "Dawn",
  "Curiosity",
  "Opportunity",
  "Spirit",
  "Sojourner",
  "Apollo",
  "Gemini",
  "Mercury",
  "Sputnik",
  "Vostok",
  "Voskhod",
  "Soyuz",
  "Space Shuttle",
  "Enterprise",
  "Columbia",
  "Challenger",
  "Discovery",
  "Atlantis",
  "Endeavour",
  "Intrepid",
  "Daedalus",
  "Icarus",
  "Pegasus",
  "Bellerophon",
  "Perseus",
  "Jason",
  "Argonaut",
  "Odysseus",
  "Achilles",
  "Hector",
  "Agamemnon",
  "Menelaus",
  "Helen",
  "Paris",
  "Priam",
  "Cassandra",
  "Andromache",
  "Hecuba",
  "Aeneas",
  "Dido",
  "Romulus",
  "Remus",
  "Caesar",
  "Augustus",
  "Nero",
  "Caligula",
  "Claudius",
  "Vespasian",
  "Titus",
  "Domitian",
  "Nerva",
  "Trajan",
  "Hadrian",
  "Antoninus",
  "Marcus",
  "Commodus",
  "Septimius",
  "Caracalla",
  "Macrinus",
  "Elagabalus",
  "Severus",
  "Maximinus",
  "Gordian",
  "Philip",
  "Decius",
  "Valerian",
  "Gallienus",
  "Claudius",
  "Aurelian",
  "Tacitus",
  "Probus",
  "Carus",
  "Diocletian",
  "Maximian",
  "Constantius",
  "Galerius",
  "Constantine",
  "Licinius",
  "Julian",
  "Jovian",
  "Valentinian",
  "Valens",
  "Gratian",
  "Theodosius",
  "Arcadius",
  "Honorius",
  "Valentinian",
  "Maximus",
  "Avitus",
  "Majorian",
  "Leo",
  "Zeno",
  "Anastasius",
  "Justin",
  "Justinian",
  "Maurice",
  "Phocas",
  "Heraclius",
  "Constans",
  "Constantine",
  "Theodosius",
  "Justinian",
  "Philippicus",
  "Anastasius",
  "Theodosius",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
  "Nicephorus",
  "Leo",
  "Basil",
  "Romanus",
];
